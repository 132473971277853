const handleDropChip = (array, id, typeOperation) => {
  if (typeOperation === 'id') {
    array.splice(
      array.findIndex(i => i.id === id),
      1
    );
  } else {
    array.splice(
      array.findIndex(i => i === id),
      1
    );
  }
};

export default handleDropChip;
